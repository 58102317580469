import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import HomePageComponent from '../components/homePage/homePage';
import { withTranslation } from 'react-i18next';
import { getLanguageDefault } from '../utils/Utils';
import CouponsManagerAPI from '../services/coupon';
import LoadingComponent from '../common/Loading';
import './styles/indexPage.scss';
import { filterRegion } from '../utils/funtionCommon';

const Donate = props => {
  const { t, i18n } = props;
  const [regions, setRegion] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchRegionData = async () => {
    setIsLoading(true);
    try {
      const resRegions = await CouponsManagerAPI.getRegions();
      if (resRegions && resRegions.status === 200) {
        setRegion(filterRegion(resRegions.data.records));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setRegion([]);
      }
    } catch (error) {
      setIsLoading(false);
      setRegion([]);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(getLanguageDefault().code.toLowerCase());
    fetchRegionData();
  }, []);

  return (
    <Layout isShowAction={false} minHeight="100%">
      <Seo title="Home" />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <HomePageComponent
          t={t}
          i18n={i18n}
          languageDefault={getLanguageDefault()}
          regions={regions}
          setRegion={setRegion}
        />
      )}
    </Layout>
  );
};

export default withTranslation('blog')(Donate);
